import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const FotovoltaicoResidenziale = () => (
  <Layout isPagina={true}>
    <SEO
      title="Impianti fotovoltaici con detrazione IRPEF 50% Lazio, Roma, Aprilia, Pomezia, Latina, Anzio, Nettuno"
      description="Impianti fotovoltaici residenziali per la tua abitazione a Roma Anzio Nettuno Aprilia Ardea Pomezia Latina"
    />
    <BanneronePage title="Fotovoltaico Residenziale" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Realizzazione di impianti fotovoltaici residenziali Lazio, Anzio, Nettuno, Aprilia, Ardea, Pomezia, Cisterna, Latina, Roma" />
      <Row className="mx-1">
        <CardPagina variante="white" className="mt-4">
          <Card.Body>
            <Row>
              <Col>
                <Badge className="blink">
                  <span>ESCLUSIVA</span>
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col className="offerta" md={7}>
                <h2>
                  Offerta realizzazione Impianti Fotovoltaici residenziali
                </h2>
                <p>
                  <em>"chiavi in mano"</em> Lazio, Anzio, Nettuno, Aprilia,
                  Ardea, Pomezia, Cisterna, Latina, Roma.
                </p>
                <h4>
                  DETRAZIONE IRPEF DEL 50% SUL TOTALE DELL'IMPIANTO FOTOVOLTAICO
                </h4>
                <br />

                <h3>prezzo medio € 1.300 al kWp (IVA inclusa)</h3>
                <Row>
                  <Col lg={6} />
                  <Col lg={6}>
                    <BottoneBianco
                      className="mt-3 float-right variantePage"
                      title="Richiedi un preventivo"
                      link="richiesta-preventivo"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <img
                  className="img-fluid mx-auto"
                  src="../impianto-fotovoltaico.jpg"
                  alt="Offerta impianto Fotovoltaico lazio"
                />
              </Col>
            </Row>
          </Card.Body>
        </CardPagina>
      </Row>
      <CardPagina variante="bordoBottom gridParity white" className="">
        Con NSE srl la GRID PARITY nel Lazio è stata raggiunta: un kWh da
        fotovoltaico (0,10-0,12 €) costa meno di un kWh da combustibili fossili
        (0,17-0,20 €)
      </CardPagina>
      <Sezione title="Dettagli">
        <Tabs
          className="text-center mx-auto nav-justified"
          defaultActiveKey="servizi"
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="servizi"
            title={
              <i className="fa fa-sun-o" aria-hidden="true">
                &nbsp; Servizi Inclusi Nell'Offerta
              </i>
            }
          >
            <div className="m-4 text-justify">
              <ul>
                <li>
                  <p>
                    <span>
                      Sopralluogo gratuito con valutazione della fattibilità e
                      previsione del ritorno economico dell'impianto;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Presentazione dei documenti presso gli uffici competenti e
                      gestione dei rapporti con le autorità comunali per
                      l'ottenimento dell'autorizzazione necessaria;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Eventuale pratica di finanziamento agevolato;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Richiesta di connessione ad e-distribuzione / ACEA o altro
                      gestore con progetto preliminare;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Fornitura e posa in opera dell'impianto fotovoltaico
                      comprensivo di moduli fotovoltaici, inverter, sistemi di
                      protezione, quadri elettrici necessari, struttura di
                      supporto e tutto quanto necessario per una realizzazione a
                      regola d'arte (dettaglio dei prodotti impiegati riportato
                      a fondo pagina);
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Direzioni lavori;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Collaudo e redazione della Dichiarazione di Conformità;
                      dell'impianto secondo il D.M. 37/08 (ex. L. 46/90);
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Redazione del progetto definitivo con elaborati grafici di
                      dettaglio ed allegati tecnici;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Assistenza tecnica nella fase di allaccio dell'impianto
                      alla rete di distribuzione;
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Espletamento pratiche con il GSE per l'ottenimento dello
                      SCAMBIO SUL POSTO (SSP) o del Ritiro Dedicato (RID);
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>
                        Servizio di assistenza nei rapporti con il GSE e
                        monitoraggio post-vendita per 2 anni
                      </b>
                      , dopo la firma della convenzione dello Scambio sul Posto
                      (SSP) o del RID.
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>
                        <span>
                          SERVIZIO DI ASSISTENZA PER L'INTERA VITA TECNICA
                          DELL'IMPIANTO!!!!
                        </span>
                      </b>
                    </span>
                    <b>
                      <br />
                    </b>
                  </p>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab eventKey="prodotti" title="Prodotti e Componenti">
            <div className="m-4">
              <p>
                <span>In qualità di </span>
                <span>
                  <b>
                    consulenti ed installatori accreditati delle società QCELLS
                    GmbH, HUAWEI, TRINA Solar, SUNPOWER, SHARP, REC, etc.
                  </b>
                </span>
                <span>
                  il set di PRODOTTI offerti è composto da:
                  <br />
                </span>
                <br />
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      <b>Moduli fotovoltaici</b> - Moduli QCELLS/TRINA Solar/SUNPOWER in
                      silicio monocristallino con la nuova tecnologia N-Type ad elevata producibilità. Certificazioni:
                      conformità alle norme IEC / EN 61215 Ed. 2.0 ed IEC 61730.
                      Garanzia su difetti nei materiali impiegati: 25/30 anni.
                      Garanzia di potenza: non inferiore al 94% rispetto al
                      valore di targa nei primi 12 anni di funzionamento e non
                      inferiore all'85% dopo 25 anni. La scelta dei moduli, su
                      espresse richieste del cliente potrà riguardare anche
                      differenti case italiane, tedesche, giapponesi: SOLARWATT,
                      SHARP, SUNPOWER, REC, HUAWEI, etc.
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>Inverter</b> - Inverter SOLAREDGE, KOSTAL, ZCS, SOLIS, FRONIUS, SMA, etc.
                   conformi a tutte le norme CEI EN vigenti.{" "}
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>Strutture di sostegno</b> - Strutture in alluminio
                      certificato e strutturale per tetti a falda, tettoie o pergole in
                      sostituzione della copertura esistente, su superficie
                      piane o su terra con relative zavorre in cemento armato
                      vibrato con certificato in galleria del vento. Bulloneria, sigillanti bituminosi e resine
                      speciali per garantire l'impermeabilizzazione e
                      l'ancoraggio in modo stabile e sicuro nel tempo. CONTACT
                      ITALIA, SUNBALLAST, FISHER.
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>Linea elettrica FV </b>- Cavi con protezione aggiuntiva
                      ai raggi UV ed altri agenti atmosferici, in modo tale da
                      garantirne la durata nel tempo. I cavi saranno del tipo
                      unipolare con sezione da 4/6/10 mmq (FG21M21 PV 20),
                      garantiti 20 anni a 130 °C di esercizio.
                      <br />
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <b>Quadri</b> Quadri BTICINO/SIEMENS/ABB rispondenti a tutte
                      le Norme CEI di riferimento. Gli involucri saranno di
                      materiale isolante, con grado di protezione IP pari a 65
                      se in esterno, e IP 21 se interno. All'interno del quadro
                      in CC, saranno presenti: fusibili di protezione e
                      scaricatori per ogni polo di ogni stringa (con relativo
                      impianto di terra), un interruttore di
                      manovra-sezionatore.{" "}
                    </span>
                    <br />
                  </p>
                </li>
              </ul>
            </div>
          </Tab>
        </Tabs>
      </Sezione>
      <CardPagina variante="bordoTop gridParity text-center">
        Impianti fotovoltaici Lazio con sistemi di accumulo: autoconsumo fino al
        100% - autonomia dalla rete
        <br />
        <BottoneBianco
          className="mt-3 text-center variantePage"
          title="Richiedi un preventivo"
          link="richiesta-preventivo"
        />
      </CardPagina>
    </Container>
  </Layout>
)

export default FotovoltaicoResidenziale
